/**
 * Truncate Text as long as the limit. If the string length is more than the limit, change the rest of the content to ... 
 * If string is url or long text without space It will split every 25 character to make it word break
 * @param text {string} string that will be truncated,
 * @param limit {number} number of limit of string length before it will be truncated
 * @param wordwrap {boolean} condition for long text without space
 */

export const truncateText = (text, limit = 50, wordwrap = false) => {
    if(text) {
        let title = text;
        if(wordwrap && !limit) {
            title = title.match(/.{1,32}/g) || [];
            title = title.join(" ");
        } else if(wordwrap && limit > 50) {
            if (title.length >= limit) {
                title = title.replace(/<\/?[^>]+(>|$)/g, "");
                title = title.substring(0, limit) + "..."
            }
        } else {
			if (title.length >= limit) title = title.substring(0, limit) + "..."
        }
        return title
    }
}